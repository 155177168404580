import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Parmina – оренда футбольного поля
			</title>
			<meta name={"description"} content={"Ваше місце для оренди футбольного поля"} />
			<meta property={"og:title"} content={"Головна | Parmina – оренда футбольного поля"} />
			<meta property={"og:description"} content={"Ваше місце для оренди футбольного поля"} />
			<meta property={"og:image"} content={"https://parminacamb.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://parminacamb.com/img/8305791.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://parminacamb.com/img/8305791.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://parminacamb.com/img/8305791.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://parminacamb.com/img/8305791.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://parminacamb.com/img/8305791.png"} />
			<meta name={"msapplication-TileImage"} content={"https://parminacamb.com/img/8305791.png"} />
			<meta name={"msapplication-TileColor"} content={"https://parminacamb.com/img/8305791.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.5) 100%),#FFFFFF url(https://parminacamb.com/img/1.jpg) 100% 100% /100% no-repeat scroll padding-box"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="80%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Parmina
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
				Ласкаво просимо до Parmina, де ваша пристрасть до футболу поєднується з першокласними зручностями та неперевершеним сервісом. Незалежно від того, чи є ви досвідченим гравцем, який прагне відточити свої навички, групою друзів, які шукають змагань, чи організацією, якій потрібне професійне поле для проведення заходів, у нас є все, що вам потрібно, щоб сповна насолодитися прекрасною грою.
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center">
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--light"
						background="--color-primary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
						flex-direction="row"
					>
						Контакти
					</Link>
					<Link
						href="/services"
						padding="12px 24px 12px 24px"
						color="--darkL1"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-darkL1"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						hover-transition="border 0.2s ease-in-out 0s"
						hover-border-color="--color-orange"
						transition="border 0.2s ease-in-out 0s"
					>
						Наші послуги
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про нас
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
				У Parmina ми віримо в те, що пропонуємо більше, ніж просто футбольне поле – ми пропонуємо досвід. Наші найсучасніші приміщення створені для гравців усіх рівнів, від аматорів до професійних спортсменів. Наше прагнення до досконалості гарантує, що кожна гра на нашому полі буде незабутньою та приємною. З ретельно доглянутими територіями та зручностями преміум-класу ми прагнемо створити середовище, де ви зможете грати якнайкраще.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Високоякісний газон: 
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						на нашому полі представлено першокласний синтетичний газон, який імітує натуральну траву, забезпечуючи оптимальну продуктивність і знижуючи ризик травм.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://parminacamb.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://parminacamb.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Освітлення та зручності: 
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						насолоджуйтесь вечірніми іграми з нашою професійною системою освітлення та скористайтеся перевагами наших чистих, сучасних роздягалень і душових.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Доступність: 
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						зручне розташування з достатньою парковкою гарантує, що дістатися до гри буде якомога легше.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://parminacamb.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://parminacamb.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Чому варто вибрати нас?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Вибір Parmina означає вибір якості, зручності та чудового футбольного досвіду. Ми з нетерпінням чекаємо можливості подарувати вам винятковий футбольний досвід.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});